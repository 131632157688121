<template>
  <div class="examine_detail">
    <back />
    <div class="content globle_border">
      <el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
        <div class="title1">修改商超信息</div>
        <div class="shop_img">
          <el-form-item label="营业执照">
            <el-image class="busilicense" fit="cover" :src="ruleForm.businessLicenseUrl" :preview-src-list="[ruleForm.businessLicenseUrl]"></el-image>
          </el-form-item>
          <el-form-item label="店铺logo" class="shop">
            <!-- <el-image class="busilicense" fit="cover" :src="ruleForm.businessLicenseUrl" :preview-src-list="[ruleForm.businessLicenseUrl]"></el-image> -->
            <el-upload class="avatar-uploader" accept=".png,.jpeg,.jpg" :action="actions" :limit="1" name="multipartFile" :file-list="fileList" :on-success="handleAvatarSuccess" :show-file-list="false" :before-upload="beforeUploads">
              <img v-if="ruleForm.logoUrl" :src="ruleForm.logoUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <el-form-item label="公司名称">
          <el-input v-model="ruleForm.companyName" disabled></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="ruleForm.creditCode" disabled></el-input>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="ruleForm.shopName" disabled></el-input>
        </el-form-item>
        <el-form-item label="关联学校">
          <el-input v-model="ruleForm.schoolName" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactMan">
          <el-input v-model="ruleForm.contactMan"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactTel">
          <el-input v-model="ruleForm.contactTel"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="selectedOptions">
          <!-- <el-cascader ref="cascader" v-model="ruleForm.selectedOptions" style="width: 100%;" :options="options" :props="regionParams" @change="handleChange"></el-cascader> -->
          <el-cascader ref="cascader" disabled v-model="ruleForm.selectedOptions" style="width: 60%" :options="addressList" :props="regionParams" class="cascader" @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="ruleForm.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <div class="Runecloth">
            <VueEditor :config="config" ref="vueEditor" />
          </div>
        </el-form-item>
        <el-form-item class="footer">
          <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
          <!-- <el-button @click="resetForm('ruleForm')">取消</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import VueEditor from "vue-word-editor"
import "quill/dist/quill.snow.css"
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      id: null,
      fileList: [],
      addressList: [],

      aa: ["44", "4401", "440106"],
      regionParams: { label: "name", value: "name", expandTrigger: "hover" },
      // options: require('../../../assets/pca-code.json'), // 省市区数据(三维数组)
      ruleForm: {
        companyName: "", // 公司名称
        shopName: "", // 店铺名称
        schoolId: "", // 学校id
        schoolName: "", // 学校name
        introduction: "", // 简介
        selectedOptions: [], // 选择的地区
        address: "", // 详细地址
        contactMan: "", // 联系人
        contactTel: "", // 联系电话
        businessLicense: "", // 营业执照id
        busiLicenseUrl: "", // 营业执照url
        creditCode: "", // 统一社会信用代码
        password: "", // 密码
        confirmPassword: "", // 确认密码
        province: "", // 省
        city: "", // 市
        area: "", // 区
        provinceCode: "", // 省
        cityCode: "", // 市
        areaCode: "", // 区
      },
      actions: this.$axios.defaults.baseURL + this.$api.uploadFile,
      rules: {
        contactMan: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        selectedOptions: [{ required: true, message: "请选择所在地区", trigger: "blur" }],
        contactTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请输入有效的手机号码", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
      },

      config: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
          ],
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error("请上传图片格式,如JPG,JPEG,PNG文件格式")
            } else {
              insert(res.data.result.fileUrl)
            }
          },
          showProgress: false,
        },
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.schoolDetail()
    this.getAddress()
  },
  methods: {
    // 详情
    schoolDetail() {
      this.$axios.get(this.$api.shopDetails + this.id).then(res => {
        this.ruleForm = res.data.result
        this.ruleForm.selectedOptions = [this.ruleForm.province, this.ruleForm.city, this.ruleForm.area]
        // console.log(this.ruleForm.selectedOptions);
        this.$nextTick(() => {
          let quill = this.$refs.vueEditor.editor
          quill.clipboard.dangerouslyPasteHTML(0, this.ruleForm.introduction)
        })
      })
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.introduction = this.$refs.vueEditor.editor.root.innerHTML
          this.ruleForm.id = this.id
          // console.log(this.ruleForm);
          this.$axios.put(this.$api.shopUpdate, this.ruleForm).then(res => {
            if (res.data.code == 100) {
              this.$message({
                message: res.data.desc,
                type: "success",
              })
              this.$router.replace("/shopSetup/storeSettings")
            }
          })
        }
      })
    },
    getAddress() {
      this.$axios.get(this.$api.getDistrictInfo).then(res => {
        if (res.data.code == 100) {
          // console.log(res);
          this.addressList = res.data.result
        }
      })
    },
    // 地址
    handleChange(value) {
      // console.log('表单',this.ruleForm);
      // console.log('地址', e);

      // 注意：path、pathLabels为getCheckedNodes自带的键名
      // let codes = this.$refs['cascader'].getCheckedNodes()[0].path
      let checkedNodes = this.$refs["cascader"].getCheckedNodes()
      if (checkedNodes.length === 0) {
        console.log("没有选择任何地址")
      } else {
        let names = checkedNodes[0].pathLabels
        // console.log(names);
        this.ruleForm.province = names[0]
        this.ruleForm.city = names[1]
        this.ruleForm.area = names[2]
        this.ruleForm.provinceCode = value[0]
        this.ruleForm.cityCode = value[1]
        this.ruleForm.areaCode = value[2]
      }
    },
    // 店铺logo
    handleAvatarSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.logoUrl = res.result.fileUrl
        this.$message({ showClose: true, message: res.desc, type: "success" })
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
  },
}
</script>

<style scoped lang="scss">
.examine_detail {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid #8c939d;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .el-popper .el-cascader__dropdown {
    left: 562px !important;
  }
  .shop_img {
    display: flex;
    .shop {
      margin-left: 276px;
    }
    .busilicense {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
      margin-left: 50px;
    }
  }

  .content {
    padding: 10px 190px;
    .form {
      .Runecloth {
        margin-left: 32px;
        width: 734px;
      }
      .footer {
        text-align: center;
      }
      .title1,
      .title2 {
        position: relative;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title1::before,
      .title2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        width: 4px;
        height: 22px;
        background-color: #069bbc;
      }

      .title2 {
        margin-top: 60px;
      }
      /deep/.el-form-item__content {
        margin-bottom: 30px;
      }
      /deep/.el-input {
        width: 650px;
        margin-left: 30px;
      }
      /deep/.el-form-item__error {
        margin-left: 50px;
      }
      /deep/ .el-form-item__label {
        color: #272727;
        font-size: 16px;
      }
      /deep/.el-textarea {
        width: 650px;
        margin-left: 50px;
      }
      /deep/ .el-input__inner,
      /deep/.el-textarea__inner {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e5e7e6;
      }
      /deep/.el-textarea .el-input__count {
        background: transparent !important;
        bottom: -8px;
        font-size: 10px;
      }
      /deep/ .el-input.is-disabled .el-input__inner,
      /deep/ .el-textarea.is-disabled .el-textarea__inner {
        color: #1a1a1a;
      }
    }
  }
  .cascader {
    width: 650px;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 650px;
    }
  }
}
</style>
